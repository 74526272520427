<template>
  <b-modal
      id="confirmation-governance-modal"
      scrollable
      no-close-on-backdrop
      :hide-footer="conData && conData.waiting"
      hide-header-close
      centered
      ref="confirmation-governance-modal">
    <template v-slot:modal-title>
      <span v-if="conData && conData.waiting">Waiting for confirmation</span>
      <span class="mb-0 text-danger" v-else-if="conData && conData.modalType === 'error'">Transaction failed</span>
      <span class="mb-0" v-else-if="conData && conData.modalType === 'success'">Success</span>
      <span class="mb-0" v-else-if="conData">{{ conData.title }}</span>
      <span class="modal-close-icon" clickable v-on:click="hideModal">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5.63672" y="16.9502" width="16" height="2" rx="1" transform="rotate(-45 5.63672 16.9502)" fill="#B5B5C3"/>
        <rect x="7.05078" y="5.63574" width="16" height="2" rx="1" transform="rotate(45 7.05078 5.63574)" fill="#B5B5C3"/>
      </svg>
    </span>
    </template>
    <b-overlay :show="conData && conData.waiting"
               :variant="'transparent'"
               :opacity="'0.25'"
               :blur="'1px'"
               rounded="sm"
               v-if="conData">
      <p class="mt-2 mb-7 text-danger" v-if="conData.modalType === 'error' && conData.errorMessage">{{ conData.errorMessage }}</p>
      <p class="mt-2 mb-7 text-success" v-if="conData.modalType === 'success' && conData.successMessage">{{ conData.successMessage }}</p>
      <div class="my-3 font-weight-bolder" v-if="conData.subtitle">
        {{ conData.subtitle }}
      </div>
      <b-row align-v="center" v-if="conData.pool" no-gutters align-h="between">
        <b-col class="jumbotron text-center">
            <div class="caption">{{ conData['pool']['from']['title'] }}</div>
            <div class="d-flex justify-content-center">
                <div>{{ conData['pool']['from']['value'] }}</div>
                <div class="text-primary ml-2">{{ conData['pool']['from']['name'] }}</div>
            </div>
        </b-col>
      </b-row>
      <b-table small borderless  class="mt-4 mb-0"
               :items="conData.listData"
               :fields="fields"
               thead-class="d-none"
               v-if="conData.listData && conData.listData.length > 0">
        <template v-slot:cell(name)="{value}" class="p-0">
          <span class="caption">{{ value }}</span>
        </template>
        <template v-slot:cell(data)="{item}">
          <span class="mr-3">{{ item.data }}</span><span class="caption">{{ item.dataText }}</span>
        </template>
      </b-table>
      <div class="mt-4 mb-0" v-if="conData.caption">{{ conData.caption }}</div>
    </b-overlay>
    <template v-slot:modal-footer v-if="conData && !conData.waiting">
      <div v-if="conData && conData.etherscanURL" class="mr-auto">
        <b-button @click="openEtherscan()"
                  class="px-7 button-stroked button-small">
          View on etherscan
        </b-button>
      </div>
      <b-button variant="gray"
                v-on:click="hideModal"
                class="mr-3">
        Close
      </b-button>
      <b-button variant="primary"
                v-on:click="confirm"
                v-if="conData && conData.modalType === 'confirm'">
        Confirm
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmationGovernanceModal",
  props: {
    conData: {
      title: String,
      subtitle: String,
      modalType: String,
      pool: {
        from: {
          title: String,
          name: String,
          value: String
        },
      },
      listData: Array,
      caption: String,
      waiting: Boolean,
      etherscanURL: String
    }
  },
  data: function () {
    return {
      fields: ['name', 'data'],
    }
  },
  methods: {
    hideModal() {
      this.$refs['confirmation-governance-modal'].hide();
    },
    confirm() {
      this.$emit('ok', true);
    },
    openEtherscan() {
      window.open(this.conData.etherscanURL || 'https://etherscan.io/', '_blank');
    }
  }
}
</script>

<style scoped lang="scss">

  .caption {
  font-size: $font-size-base;
}

</style>
